import { render, staticRenderFns } from "./index.vue?vue&type=template&id=58dd5fa9&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!R:\\.apache\\www\\public\\sp\\gdps\\sp\\frontend\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "index.vue"
export default component.exports