<template>
  <header
    class="gd-rodo-step-header"
    :class="{ 'gd-rodo-step-header--active': active, 'gd-rodo-step-header--completed': completed }"
  >
    <gdBulletStep
      :stepActive="active"
      :stepSuccess="completed"
    />
    <h1 class="gd-rodo-step-header__title">{{ completed ? completedTitle : title }}</h1>
    <h2
      :style="{ color: sideTitle.color }"
      class="gd-rodo-step-header__sidetitle"
      v-if="sideTitle"
    >{{ sideTitle.text }}</h2>
    <gdIcon
      v-if="iconName"
      class="gd-rodo-step-header__icon"
      :name="iconName"
      :large="true"
    />
  </header>
</template>
<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      completedTitle: {
        type: String,
        required: true
      },
      sideTitle: {
        type: Object
      },
      iconName: {
        type: String
      },
      active: {
        type: Boolean
      },
      completed: {
        type: Boolean
      }
    },
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/mixins/breakpoints.scss";

  .gd-rodo-step-header {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__title {
      font-size: 20px;
      line-height: 48px;
      text-align: left;
      transition: .15s;

      @include media(1300px) {
        font-size: 18px;
      }
    }

    &__sidetitle {
      font-size: 16px;
      line-height: 20px;
      color: $color-gray-light;
    }

    &__icon {
      color: lighten($color-gray-light, 40);
    }

    &__description {
      width: 100%;
    }

    .gd-bullet-step {
      position: absolute;
      top: 10px;
      left: -100px;
    }
  }

  .gd-rodo-step-header--active {
    .gd-rodo-step-header__title {
      font-size: 25px;
      text-align: left;
      color: $color-blue;
    }

    .gd-rodo-step-header__icon {
      color: $color-dark;
    }
  }

  .gd-rodo-step-header--completed {
    .gd-rodo-step-header__icon {
      color: $color-dark;
    }
  }
</style>
