<template>
  <gdAccount />
</template>

<script>
  import gdAccount from "@/pages/app/gd-account";
  import Store from "@/stores";

  export default {
    metaInfo() {
      return this.$gdMetaInfo();
    },

    async beforeRouteEnter(to, from, next) {
      try {
        await Store.dispatch("user/GET_USER_PAYMENTS");
        await Store.dispatch("user/GET_COMPANY");
        next();
      } catch (error) {
        console.error(`Couldn't resolve gd-account beforeRouteEnter requests`, {
          error
        });
        next({
          name: "app.error",
          query: {
            type: "API"
          }
        });
      }
    },

    components: {
      gdAccount
    }
  };
</script>
