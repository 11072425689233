<template>
  <gdBrick :class="rodoStepClass">
    <gdRodoStepHeader
      :title="'Stwórz profil' + (!step1.questionsAnswered ? ` - pytanie ${step1.activeQuestionIndex + 1} z ${step1.questions.length}` : '')"
      completedTitle="Profil utworzony"
      iconName="user"
      :active="step1.active"
      :completed="step1.completed"
    />
    <gdGrowTransition>
      <gdGrowTransitionContent v-if="step1.active">
        <template
          slot-scope="props"
          v-if="step1.active"
        >
          <section
            class="gd-rodo-step__content"
          >
            <gdRodoStepQuestions
              v-if="step1.questions"
              :items="step1.questions"
              :questionsAnswered="step1.questionsAnswered"
              questionsAnsweredMessage="Dziękujemy za udzielenie odpowiedzi, przygotowaliśmy dla Ciebie Rejestr Czynności do wypełnienia."
              :stepId="stepId"
            />
            <section class="gd-rodo-step__content-extra">
              <gdButton
                v-if="step1.activeQuestionIndex > 0"
                theme="ghost"
                @click.native="previousQuestion()"
              >
                <gdImg
                  src="/assets/icons/icon-arrow-purple.svg"
                />
              </gdButton>
            </section>
          </section>
        </template>
      </gdGrowTransitionContent>
    </gdGrowTransition>
  </gdBrick>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdBrick from '@/components/bricks/gd-brick'
  import gdRodoStepHeader from '@/components/rodo-steps/gd-rodo-step-header'
  import gdRodoStepQuestions from '@/components/rodo-steps/gd-rodo-step-questions'

  export default {

    data() {
      return {
        stepId: 1
      }
    },

    computed: {
      ...mapGetters('rodoSteps', ['step1']),
      rodoStepClass () {
        return {
          'gd-rodo-step gd-rodo-step--1': true,
          'gd-rodo-step--active': this.step1.active,
          'gd-rodo-step--completed': this.step1.completed,
        }
      }
    },

    methods: {
      completeStep(){
        this.$store.dispatch('rodoSteps/COMPLETE_STEP', { stepId: this.stepId })
      },

      async previousQuestion(){
        const activeQuestion = this.step1.questions[this.step1.activeQuestionIndex]
        await this.$store.dispatch('rodoSteps/PREVIOUS_QUESTION', { stepId: this.stepId, question: activeQuestion })
      }
    },

    components: {
      gdBrick,
      gdRodoStepHeader,
      gdRodoStepQuestions
    }
  }
</script>
