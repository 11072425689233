import { render, staticRenderFns } from "./payment-await.vue?vue&type=template&id=ab12063c&"
import script from "./payment-await.vue?vue&type=script&lang=js&"
export * from "./payment-await.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!R:\\.apache\\www\\public\\sp\\gdps\\sp\\frontend\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "payment-await.vue"
export default component.exports