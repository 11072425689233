<template>
  <section class="gd-account">
    <gdPageHeader
      title="Twoje konto"
    />
    <gdBricksWrapper>
      <gdBrick slot="wide">
        <gdFormUserAccount/>
      </gdBrick>
      <gdBrick slot="wide">
        <gdFormUserPasswordChange/>
      </gdBrick>
      <gdBrickAccountPlan
        slot="thin"
        :planName="userPayments.name"
        :oneTimePrice="userPayments.price"
        :planPrice="userPayments.price"
        :startDate="userPayments.startDate"
        :endDate="userPayments.endDate"
        :planInvoices="invoices"
      />
      <gdBrickLogout
        slot="thin"
      />
    </gdBricksWrapper>
    <div
      v-if="user.isAdmin"
      class="gd-account__admin-area"
    >
      <gdAccountDivider
        title="Użytkownicy"
      />
      <gdTable class="gd-account-table">
        <gdAccountUsersList
          :users="companysUsers"
        />
      </gdTable>
      <gdAccountDivider
        title="Dodaj użytkownika"
      />
      <gdBricksWrapper>
        <gdBrick slot="wide">
          <gdFormUserInvite/>
        </gdBrick>
      </gdBricksWrapper>
    </div>
  </section>
</template>

<script>
  import { mapGetters } from "vuex";
  import gdBrick from "@/components/bricks/gd-brick";
  import gdTable from "@/components/tables/elements/gd-table";
  import gdPageHeader from "@/components/page/gd-page-header";
  import gdAccountDivider from "@/components/account/gd-account-divider";
  import gdAccountUsersList from "@/components/account/gd-account-users-list";
  import gdBricksWrapper from "@/components/bricks/gd-bricks-wrapper";
  import gdFormUserAccount from "@/components/forms/gd-form-user-account";
  import gdBrickAccountPlan from "@/components/bricks/gd-brick-account-plan";
  import gdBrickLogout from "@/components/bricks/gd-brick-logout";
  import gdBrickDataProcessingInfo from "@/components/bricks/gb-brick-data-processing-info";
  import gdFormUserPasswordChange from "@/components/forms/gd-form-user-password-change";
  import gdFormUserInvite from "@/components/forms/gd-form-user-invite";

  export default {
    data() {
      return {
        invoices: [
          {
            date: "19.01.2018",
            number: "FV/2851",
            url: "#"
          },
          {
            date: "19.01.2017",
            number: "FV/2571",
            url: "#"
          }
        ]
      };
    },

    computed: {
      ...mapGetters("payments", ["chosenPlanIndex", "paymentPlans"]),
      ...mapGetters("user", ["user", "userPayments", "companysUsers"])
    },

    components: {
      gdBrick,
      gdTable,
      gdPageHeader,
      gdBrickLogout,
      gdBricksWrapper,
      gdAccountDivider,
      gdAccountUsersList,
      gdFormUserAccount,
      gdBrickAccountPlan,
      gdFormUserPasswordChange,
      gdBrickDataProcessingInfo,
      gdFormUserInvite
    }
  };
</script>

<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";

  .gd-account {
    .gd-brick__inner {
      padding: 38px 46px 30px;
    }

    form {
      width: 100%;
      text-align: left;
    }

    .gd-users-list {
      &__header {
        border-bottom: 2px solid $color-gray-semi-light;

        .gd-table-cell:first-of-type {
          width: 100px;
          max-width: 340px;
        }
      }

      &__body {
        .gd-table-cell {
          min-height: 50px;
          font-size: 16px;
          color: $color-gray-light;

          &:first-of-type {
            width: 100px;
            max-width: 340px;
          }
        }
      }
    }

    &-table {
      padding: 0;
      border: 1px solid $color-brick-border;

      .gd-table__wrapper {
        margin: 0;
      }

      .gd-table__wrapper::before,
      .gd-table__wrapper::after {
        content: none;
      }
    }
  }
</style>
