import { render, staticRenderFns } from "./gd-password-reset.vue?vue&type=template&id=1c1e7fc4&"
import script from "./gd-password-reset.vue?vue&type=script&lang=js&"
export * from "./gd-password-reset.vue?vue&type=script&lang=js&"
import style0 from "./gd-password-reset.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!R:\\.apache\\www\\public\\sp\\gdps\\sp\\frontend\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "gd-password-reset.vue"
export default component.exports