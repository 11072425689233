<template>
  <gdBrick :class="rodoStepClass">
    <gdRodoStepHeader
      title="Zastosuj się do zaleceń"
      completedTitle="Zalecenia zastosowane"
      iconName="list"
      :active="step5.active"
      :completed="step5.completed"
    />
    <gdGrowTransition>
      <gdGrowTransitionContent v-if="step5.active">
        <div
          class="gd-rodo-step__content"
        >
          <p>{{ step5.description }}</p>
          <div class="question__buttons">
            <gdButton :link="{ name: 'app.recommendations-report' }">Zobacz zalecenia</gdButton>
            <gdButton
              @click.native="completeStep"
              v-if="allowedResources.recommendation.allCompleted"
            >
              Kontynuuj
            </gdButton>
          </div>
        </div>
      </gdGrowTransitionContent>
    </gdGrowTransition>
  </gdBrick>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdBrick from '@/components/bricks/gd-brick'
  import gdRodoStepHeader from '@/components/rodo-steps/gd-rodo-step-header'

  export default {

    data() {
      return {
        stepId: 5
      }
    },

    computed: {
      ...mapGetters('rodoSteps', ['step5']),
      ...mapGetters('user', ['allowedResources']),

      rodoStepClass () {
        return {
          'gd-rodo-step gd-rodo-step--5': true,
          'gd-rodo-step--active': this.step5.active,
          'gd-rodo-step--completed': this.step5.completed,
        }
      }
    },

    methods: {
      completeStep(){
        this.$store.dispatch('rodoSteps/COMPLETE_STEP', { stepId: this.stepId })
      }
    },

    components: {
      gdBrick,
      gdRodoStepHeader
    }
  }
</script>
<style lang="scss">

</style>
