const initialState = () => ({
  user: null,
  userPayments: null,
  userUARDocs: null,
  companysUsers: null,
  token: window.localStorage.getItem('gdps-token') || null,
  allowedResources: {},
  horizontalScrollTooltip: {
    text: 'Tabela zawiera więcej elementów. Złap i przeciągnij, aby je zobaczyć',
    tooltipSeen: null
  },
  disabled: {
    routes: {
      'app.activity-register': true,
      'app.certificate': true,
      'app.courses': true,
      'app.documents': true,
      'app.insurances': true,
      'app.register-single': true,
      'app.recommendations-report': true,
      'app.report-of-compatibility': true,
      'app.services': false
    }
  }
})

const state = () => (initialState())

const actions = {

  async REGISTER_USER({ commit, dispatch }, data){
    try {
      const result = await dispatch('api/REQUEST', {
        endpoint: 'user/register',
        method: 'POST',
        data,
        useToken: false
      }, { root: true })

      commit('SET_TOKEN', { token: result.data.token })
      commit('SET_USER', { user: result.data.user })
      console.log('user/REGISTER_USER', { result })
    } catch (error) {
      console.error('user/REGISTER_USER', { error })
      throw error
    }
  },

  async LOG_IN({ commit, dispatch }, { username, password }){
    try {

      commit('RESET_STATE')

      const tokenRequest = await dispatch('api/REQUEST', {
        endpoint: 'auth/token',
        method: 'POST',
        data: {
          username,
          password
        }
      }, { root: true })

      commit('SET_TOKEN', { token: tokenRequest.data.token })

      await dispatch('GET_USER')
      await dispatch('registers/GET_REGISTERS', {}, { root: true })

      console.log('user/LOG_IN', { username, password })
    } catch (error) {
      console.error('user/LOG_IN', { error })
      throw error
    }
  },

  async LOG_OUT({ commit, dispatch }){
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'user/logout',
        method: 'POST'
      }, { root: true })

      if (data.ok) {
        commit('SET_TOKEN', { token: null })
        commit('RESET_STATE')
        commit('rodoSteps/RESET_STATE', null, { root: true})
      }
      console.log('user/LOG_OUT')
    } catch (error) {
      console.error('user/LOG_OUT', { error })
      throw error
    }
  },

  async GET_USER({ commit, dispatch }){
    try {
      const userRequest = await dispatch('api/REQUEST', { endpoint: 'user' }, { root: true })
      commit('SET_USER', { user: userRequest.data })
      await dispatch('GET_ALLOWED_RESOURCES')
    } catch (error) {
      console.error('user/GET_USER', { error })
      dispatch('LOG_OUT')
      throw error
    }
  },

  async GET_USER_PAYMENTS({ state, commit, dispatch }){
    try {
      const { data } = await dispatch('api/REQUEST', { endpoint: 'user/payment' }, { root: true })
      commit('SET_USER_PAYMENTS', { data: data[0] })
      console.log('user/GET_USER_PAYMENTS', { data })
    } catch (error) {
      console.error('user/GET_USER_PAYMENTS', { error })
      throw error
    }
  },

  async GET_ALLOWED_RESOURCES({ state, commit, dispatch }){
    try {
      const { data } = await dispatch('api/REQUEST', { endpoint: 'user/allowedresource' }, { root: true })
      console.log('user/GET_ALLOWED_RESOURCES', { data })
      commit('SET_ALLOWED_RESOURCES', { data })
    } catch (error) {
      console.error('user/GET_ALLOWED_RESOURCES', { error })
      throw error
    }
  },

  async SET_META({ state, commit, dispatch }, meta){
    try {
      const metadata = Object.assign({}, (state.user.company.metadata || {}), meta)

      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'user',
        method: 'PUT',
        data: { company: { metadata } }
      }, { root: true })

      commit('SET_USER', { user: data })
    } catch (error) {
      console.error('user/SET_META', { error })
      throw error
    }
  },

  async UPDATE_USER_DATA({ commit, dispatch }, data){

    delete data['id']
    delete data['metadata']
    delete data['username']
    delete data['legalAccept']
    delete data['isActive']
    delete data['isAdmin']
    delete data['rcpdGenerated']
    delete data['recommendationsGenerated']
    if(data.company){
      if ( data.company.nip && typeof data.company.nip !== 'string') {
        data.company.nip = data.company.nip.toString()
      }
      delete data.company['id']
      delete data.company['isActive']
      delete data.company['rcpdGenerated']
      delete data.company['certGenerated']
      delete data.company['certGeneratedDate']
      delete data.company['recommendationsGenerated']
      delete data.company['rtIntegrated']
      if ( ! data.company['metadata'] ) {
        delete data.company['metadata']
      }
    }

    try {
      const { data: response } = await dispatch('api/REQUEST', {
        endpoint: 'user',
        method: 'PUT',
        data
      }, { root: true })

      console.log('user/UPDATE_USER_DATA', { response })
    } catch (error) {
      console.error('user/UPDATE_USER_DATA', { error })
      throw error
    }
  },

  async CHANGE_PASSWORD({ dispatch }, { old, newPass, newPassRepeat }){
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'user',
        method: 'PUT',
        data: { password: {old, new: newPass, newRepeat: newPassRepeat} }
      }, { root: true })

      console.log('user/CHANGE_PASSWORD', { data })
    } catch (error) {
      console.error('user/CHANGE_PASSWORD', { error })
      throw error
    }
  },

  async RESET_PASSWORD({ dispatch }, { email }){
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'auth/reset/request',
        method: 'POST',
        data: { email }
      }, { root: true })

      console.log('user/RESET_PASSWORD', { data })
    } catch (error) {
      console.error('user/RESET_PASSWORD', { error })
      throw error
    }
  },

  async SET_NEW_PASSWORD({ dispatch }, { token, password, passwordRepeat }){
    try {
      const { result } = await dispatch('api/REQUEST', {
        endpoint: 'auth/reset',
        method: 'POST',
        data: { token, password, passwordRepeat }
      }, { root: true })

      console.log('user/SET_NEW_PASSWORD', { result })
    } catch (error) {
      console.error('user/SET_NEW_PASSWORD', { error })
      throw error
    }
  },

  async DELETE_ACCOUNT({ commit, dispatch }){
    try {
      const { data: response } = await dispatch('api/REQUEST', {
        endpoint: 'user',
        method: 'DELETE'
      }, { root: true })

      if (response.ok) {
        await commit('SET_TOKEN', { token: null })
        await commit('RESET_STATE')
        await commit('rodoSteps/RESET_STATE', null, { root: true})
      }

      console.log('user/DELETE_ACCOUNT', { response })
    } catch (error) {
      console.error('user/DELETE_ACCOUNT', { error })
      throw error
    }
  },

  async ADD_CUSTOM_COLUMN_VALUE({ commit, dispatch }, { activityId, columnId, value }){
    try {
      const { data: response } = await dispatch('api/REQUEST', {
        endpoint: 'user/column/value/custom',
        method: 'POST',
        data: { activityId, columnId, value }
      }, { root: true })

      console.log('user/ADD_CUSTOM_COLUMN_VALUE', { response })
      return response
    } catch (error) {
      console.error('user/ADD_CUSTOM_COLUMN_VALUE', { error })
    }
  },

  async DELETE_CUSTOM_COLUMN_VALUE({ commit, dispatch }, { id }){
    try {
      const { data: response } = await dispatch('api/REQUEST', {
        endpoint: `user/column/value/custom/${id}`,
        method: 'DELETE'
      }, { root: true })

      console.log('user/DELETE_CUSTOM_COLUMN_VALUE', { response })
    } catch (error) {
      console.error('user/DELETE_CUSTOM_COLUMN_VALUE', { error })
      throw error
    }
  },

  async GET_COMPANY({ commit, dispatch }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'company',
        data
      }, { root: true })

      commit('SET_COMPANYS_USERS', { data: data.users })

      console.log('user/GET_COMPANY', { data })
    } catch (error) {
      console.error('user/GET_COMPANY', { error })
      throw error
    }
  },

  async CREATE_COMPANYS_USER({ commit, dispatch }, { firstName, lastName, password, email, phone, legalAccept, legalAcceptSms, legalAcceptEmail }){
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'user',
        method: 'POST',
        data: { firstName, lastName, password, passwordRepeat: password, email, phone, legalAccept, legalAcceptSms, legalAcceptEmail }
      }, { root: true })

      if (data) {
        await dispatch('GET_COMPANY')
      }
      console.log('user/CREATE_COMPANYS_USER', { data })
    } catch (error) {
      console.error('user/CREATE_COMPANYS_USER', { error })
      throw error
    }
  },

  async DELETE_COMPANYS_USER({ commit, dispatch }, { id }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: `user/${id}`,
        method: 'DELETE'
      }, { root: true })

      if (data) {
        await dispatch('GET_COMPANY')
      }

      console.log('user/DELETE_COMPANYS_USER', { data })
    } catch (error) {
      console.error('user/DELETE_COMPANYS_USER', { error })
      throw error
    }
  },

  async GET_UAR_DOCS({ commit, dispatch }) {
    try {
      const { data } = await dispatch('api/REQUEST', {
        endpoint: 'user/uar',
        data
      }, { root: true })
      commit('SET_USERS_UAR_DOCS', { data })

      console.log('user/GET_UAR_DOCS', { data })
    } catch (error) {
      console.error('user/GET_UAR_DOCS', { error })
      throw error
    }
  },
}

const mutations = {
  SET_TOKEN(state, { token }){
    state.token = token
    const domain = window.location.hostname.split('.').splice(1).join('.')

    if(token){
      window.localStorage.setItem('gdps-token', token)
      document.cookie = `gdps-token=${token}; domain=${domain}`
    } else {
      window.localStorage.removeItem('gdps-token')
      document.cookie = `gdps-token=${token}; domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    }
  },

  SET_USER(state, { user }){
    state.user = user
  },

  SET_USER_PAYMENTS(state, { data }){
    state.userPayments = data
  },

  SET_ROUTE_ALLOWED(state, { routeName }){
    state.disabled.routes[routeName] = false
  },

  SET_ALLOWED_RESOURCES(state, { data }){
    state.allowedResources = data
  },

  SET_HORIZONTAL_SCROLL_TOOLTIP_SEEN(state, { seen }){
    state.horizontalScrollTooltip.tooltipSeen = seen
  },

  SET_COMPANYS_USERS(state, { data }){
    state.companysUsers = data
  },

  SET_USERS_UAR_DOCS(state, { data }){
    state.userUARDocs = data
  },

  RESET_STATE(state){
    window.localStorage.removeItem('gdps-token')
    Object.assign(state, initialState())
  }
}

const getters = {
  token: state => state.token,
  horizontalScrollTooltip: state => state.horizontalScrollTooltip,
  companysUsers: state => {
    if (!state.companysUsers) {
      return
    }

    return state.companysUsers.map(user => {
      const { companyRole, id, username, firstName, lastName } = user
      return {
        role: companyRole === 'ROLE_COMPANY_ADMIN' ? 'Admin' : 'Użytkownik',
        id,
        firstName: firstName || '',
        lastName: lastName || '',
        username
      }
    });
  },
  user: state => {
    if (!state.user) {
      return
    }

    const { company, email, firstName, id, isActive, lastName, legalAccept, phone, username, companyRole } = state.user

    const { recommendationsGenerated, rcpdGenerated, metadata } = state.user.company
    return {
      company: company || {
        name: null,
        nip: null,
        province: null,
        city: null,
        address: null,
        zipcode: null,
        rtIntegrated: null,
      },
      isAdmin: companyRole === 'ROLE_COMPANY_ADMIN' ? true : false,
      email: email || '',
      firstName: firstName || '',
      id,
      isActive: isActive || '',
      lastName: lastName || '',
      legalAccept,
      metadata,
      phone: phone || '',
      rcpdGenerated,
      recommendationsGenerated,
      username: username || ''
    }
  },
  userPayments: state =>  {
    if (!state.userPayments) {
      return
    }

    const { days, name, price, startDate, endDate, description } = state.userPayments

    return {
      days,
      name,
      description,
      startDate,
      endDate,
      price: `${price}` / 100 + ' zł'
    }
  },
  userMeta: state => (state.user && state.user.company.metadata ? state.user.company.metadata : {}),
  isLoggedIn: state => state.token,
  disabledRoutes(state, getters, rootState, rootGetters) {

    const allowedResources = getters.allowedResources

    return Object.keys(state.disabled.routes).reduce((res, routeName) => {
      const defaultValue = state.disabled.routes[routeName]
      const isStepCompleted = stepKey => {
        return state.user &&
          state.user.company.metadata &&
          state.user.company.metadata.steps &&
          state.user.company.metadata.steps[stepKey] &&
          state.user.company.metadata.steps[stepKey].completed
      }

      switch(routeName){

      case 'app.activity-register':
        res[routeName] = !isStepCompleted('1')
        break

      case 'app.certificate':
        res[routeName] = !(allowedResources.certificate && allowedResources.certificate.available)
        break

      case 'app.courses':
        break

      case 'app.documents':
        res[routeName] = !(allowedResources.documents && allowedResources.documents.available)
        break

      case 'app.insurances':
        break

      case 'app.register-single':
        res[routeName] = ({ id }) => {
          const registers = (rootGetters['registers/registersCollection'] || [])
          const singleRegister = (registers.find(item => id === item.id) || {})
          return !singleRegister.available
        }

        break

      case 'app.recommendations-report':
        res[routeName] = !(allowedResources.recommendation && allowedResources.recommendation.available)
        break

      case 'app.report-of-compatibility':
        res[routeName] = !(allowedResources.compatibility && allowedResources.compatibility.available)
        break

      case 'app.services':
        res[routeName] = defaultValue
        break

      default:
        res[routeName] = defaultValue
      }
      return res
    }, {})
  },
  allowedResources: state => {
    if(!state.allowedResources){
      return null
    }

    return state.allowedResources
  },
  userUARDocs: state => {
    if(!state.userUARDocs || !state.userUARDocs.length){
      return
    }

    return state.userUARDocs.sort((a, b) => b.id - a.id)
  }
}

export default {
  namespaced: true,
  state, actions, mutations, getters
}
