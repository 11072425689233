<template>
  <section class="gd-rodo-step__questions">
    <div
      class="questions"
      v-if="!questionsAnswered"
    >
      <ul
        class="questions_list"
        v-for="(question, questionIndex) in items"
        :key="questionIndex"
        v-if="question.active"
      >
        <li :class="questionClasses(question)">
          <p class="question__title">
            {{ question.question }}
          </p>
          <p
            class="question__description"
            v-if="question.info"
          >
            {{ question.info }}
          </p>
          <div class="question__answers">

            <!-- Boolean -->
            <template v-if="question.questionType === 'boolean'">
              <div class="question__buttons">
                <gdButton
                  v-for="(answer, answerIndex) in question.answers"
                  :key="answerIndex"
                  @click.native="nextQuestion({ answer })"
                >
                  {{ answer.label }}
                </gdButton>
                <gdButton
                  v-if="!question.isRequired"
                  theme="secondary"
                  @click.native="nextQuestion({ skipped: true })"
                >
                  Pomiń
                </gdButton>
              </div>
            </template>

            <!-- Select -->
            <template v-if="question.questionType === 'select'">
              <gdSelect
                v-model="answers[questionIndex]"
                :name="'question' + questionIndex"
                placeholder="Wybierz z listy..."
                :items="question.answers"
              />
              <div class="question__buttons">
                <gdButton
                  @click.native="nextQuestion({})"
                  :disabled="!answers[questionIndex]"
                >
                  Dalej
                </gdButton>
                <gdButton
                  v-if="!question.isRequired"
                  theme="secondary"
                  @click.native="nextQuestion({ skipped: true })"
                >
                  Pomiń
                </gdButton>
              </div>
            </template>

            <!-- Checkbox -->
            <template v-if="question.questionType === 'checkbox'">
              <gdCheckboxGroup
                :items="question.answers"
                v-model="answers[questionIndex]"
              />
              <div class="question__buttons">
                <gdButton
                  :disabled="!(answers[questionIndex] || []).length"
                  @click.native="nextQuestion"
                >
                  Dalej
                </gdButton>
                <gdButton
                  v-if="!question.isRequired"
                  theme="secondary"
                  @click.native="nextQuestion({ skipped: true })"
                >
                  Pomiń
                </gdButton>
              </div>
            </template>
          </div>
        </li>
      </ul>
    </div>
    <div v-else>
      <p
        class="question-answered-message"
        v-html="questionsAnsweredMessage"
      />
      <gdButton
        theme="secondary"
        @click.native="completeStep()"
        :loading="loading"
      >
        Kontynuuj
      </gdButton>
    </div>
  </section>
</template>
<script>
  export default {
    props: {
      items: Array,
      stepId: Number,
      questionsAnswered: Boolean,
      questionsAnsweredMessage: String
    },

    data(){
      return {
        loading: false,
        skippedQuestions: this.items.map(val => null),
        answers: this.items.map(val => null),
        currentQuestionValue: null
      }
    },

    computed: {
      activeQuestionIndex(){
        return this.items.findIndex(item => item.active)
      },
      activeQuestion(){
        return this.items[this.activeQuestionIndex]
      }
    },

    methods: {
      questionClasses(question){
        return `question question--type-${question.questionType}`
      },

      async nextQuestion({ skipped, answer }){

        if(skipped){
          this.skippedQuestions[this.activeQuestionIndex] = true
        }

        if(answer){
          this.answers[this.activeQuestionIndex] = answer
        }

        await this.$store.dispatch('rodoSteps/NEXT_QUESTION', { stepId: this.stepId, question: this.activeQuestion })

        const isLastQuestion = (this.activeQuestionIndex === this.items.length - 1)

        if(isLastQuestion){
          this.answerQuestions()
        }
      },

      async answerQuestions(){
        try {
          const answers = this.items.reduce((res, question, index) => {
            const { id } = question
            const skipped = this.skippedQuestions[index]
            const answer = this.answers[index]

            if(skipped){

              res.push({ skipped, questionId: id })
            } else {

              if(Array.isArray(answer)){

                answer.forEach(singleAnswer => {
                  res.push({
                    answerId: singleAnswer.value,
                    questionId: id
                  })
                })

              } else {

                res.push({
                  answerId: answer.value,
                  questionId: id
                })
              }
            }

            return res
          }, [])

          await this.$store.dispatch('rodoSteps/ANSWER_QUESTIONS', { stepId: this.stepId, answers })
        } catch (error) {
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        }
      },

      async completeStep(){
        try {
          this.loading = true
          await this.$store.dispatch('rodoSteps/COMPLETE_STEP', { stepId: this.stepId })
        } catch (error){
          this.$store.dispatch('toasts/ADD_DEFAULT_WARNING_TOAST')
          console.error({ error })
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
<style lang="scss">
  @import "./src/assets/styles/vars/colors.scss";
  @import "./src/assets/styles/vars/fonts.scss";

  .questions_list {
    width: 100%;
  }

  .question {
    &__title {
      text-align: left;
      font-family: $font-regular;
      font-size: 20px;
      color: $color-dark;
      line-height: 25px;
    }

    &__description {
      text-align: left;
      margin: 10px 0;
      font-family: $font-regular;
      font-size: 16px;
      color: $color-gray-light;
      line-height: 20px;
    }

    &__answers {
      display: flex;
      justify-content: flex-start;
      margin-top: 20px;
      margin-left: -5px;

      .gd-select {
        left: 5px;
      }
    }

    .gd-btn {
      min-width: 100px;
      margin: 0 5px;
    }

    &--type-boolean {
      .question__buttons {
        display: flex;
        margin-top: auto;

        button {
          min-width: unset;
        }
      }
    }

    &--type-select {
      .question__answers {
        flex-flow: column nowrap;
      }

      .question__buttons {
        margin-top: auto;
      }
    }

    &--type-checkbox {
      .question__answers {
        flex-direction: column;
      }

      .question__buttons {
        margin-top: auto;
      }
    }
  }
</style>
