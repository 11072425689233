<template>
  <gdBrick :class="rodoStepClass">
    <gdRodoStepHeader
      :title="`Uzupełnij profil` + (step3.active && !step3.questionsAnswered ? ` - pytanie ${step3.activeQuestionIndex + 1} z ${step3.questions.length}` : '')"
      completedTitle="Profil uzupełniony"
      iconName="user"
      :active="step3.active"
      :completed="step3.completed"
    />
    <gdGrowTransition>
      <gdGrowTransitionContent v-if="step3.active">
        <div
          class="gd-rodo-step__content"
        >
          <gdRodoStepQuestions
            v-if="step3.questions"
            :items="step3.questions"
            :questionsAnswered="step3.questionsAnswered"
            questionsAnsweredMessage="Dziękujemy za udzielenie odpowiedzi, przygotowaliśmy dla Ciebie Rejestr Kategorii Przetwarzań do wypełnienia."
            :stepId="stepId"
          />
          <section class="gd-rodo-step__content-extra">
            <gdButton
              v-if="step3.activeQuestionIndex > 0"
              theme="ghost"
              @click.native="previousQuestion()"
            >
              <gdImg
                src="/assets/icons/icon-arrow-purple.svg"
              />
            </gdButton>
          </section>
        </div>
      </gdGrowTransitionContent>
    </gdGrowTransition>
  </gdBrick>
</template>
<script>
  import { mapGetters } from 'vuex'
  import gdBrick from '@/components/bricks/gd-brick'
  import gdRodoStepHeader from '@/components/rodo-steps/gd-rodo-step-header'
  import gdRodoStepQuestions from '@/components/rodo-steps/gd-rodo-step-questions'

  export default {

    data() {
      return {
        stepId: 3
      }
    },

    computed: {
      ...mapGetters('rodoSteps', ['step3']),
      rodoStepClass () {
        return {
          'gd-rodo-step gd-rodo-step--3': true,
          'gd-rodo-step--active': this.step3.active,
          'gd-rodo-step--completed': this.step3.completed,
        }
      }
    },

    methods: {
      completeStep(){
        this.$store.dispatch('rodoSteps/COMPLETE_STEP', { stepId: this.stepId })
      },

      async previousQuestion(){
        const activeQuestion = this.step3.questions[this.step3.activeQuestionIndex]
        await this.$store.dispatch('rodoSteps/PREVIOUS_QUESTION', { stepId: this.stepId, question: activeQuestion })
      }
    },

    components: {
      gdBrick,
      gdRodoStepHeader,
      gdRodoStepQuestions
    }
  }
</script>
<style lang="scss">

</style>
